<template>
  <div>
    <h1>Success</h1>
    <p>
      You have successfully created an account.  A confirmation email will be sent to the email address you created the account with,
      along with a payment receipt if a purchase was made.
    </p>
    <router-link to="/dashboard" class="btn btn-warning" title="Proceed to Account">Proceed to Account</router-link>
    <TenantAssociationInfo />
  </div>
</template>

<script>
import TenantAssociationInfo from '@/views/public/components/company-create-tenant-association-info.vue';

export default {
  name: 'public-company-create-success',
  components: { TenantAssociationInfo }
}
</script>
