<template>
  <fragment>
    <hr />
    <p>Please click one of the links below to join an association</p>

    <div v-if="isLink2Build" class="mt-3">
      <div class="d-flex flow-row mb-2">
        <div class="flex-grow-1">Windsor Construction Association</div>
        <div><a href="https://www.wca.on.ca/members-application" target="_blank">https://www.wca.on.ca/members-application</a></div>
      </div>
      <div class="d-flex flow-row mb-2">
        <div class="flex-grow-1">London and District Construction Association</div>
        <div><a href="https://ldca.on.ca/page/joinnow" target="_blank">https://ldca.on.ca/page/joinnow</a></div>
      </div>
      <div class="d-flex flow-row mb-2">
        <div class="flex-grow-1">Grand Valley Construction Association</div>
        <div><a href="https://www.gvca.org/en/membership/become-a-member.aspx" target="_blank">https://www.gvca.org/en/membership/become-a-member.aspx</a></div>
      </div>
      <div class="d-flex flow-row mb-2">
        <div class="flex-grow-1">Niagara Construction Association</div>
        <div><a href="https://niagaraconstruction.org/membership/membership-applications/" target="_blank">https://niagaraconstruction.org/membership/membership-applications/</a></div>
      </div>
      <div class="d-flex flow-row mb-2">
        <div class="flex-grow-1">Kingston Construction Association</div>
        <div><a href="https://kca.on.ca/membership-application/" target="_blank">https://kca.on.ca/membership-application/</a></div>
      </div>
      <div class="d-flex flow-row mb-2">
        <div class="flex-grow-1">Ottawa Construction Association</div>
        <div><a href="https://oca.ca/create-account/" target="_blank">https://oca.ca/create-account/</a></div>
      </div>
      <div class="d-flex flow-row mb-2">
        <div class="flex-grow-1">Northeastern Ontario Construction Association</div>
        <div><a href="https://nocabuild.com/membership" target="_blank">https://nocabuild.com/membership</a></div>
      </div>
    </div>

    <hr />
  </fragment>
</template>

<script>

const tenants = {
  link2build: 'Link2Build',
}

export default {
  name: "CompanyCreateTenantAssociationInfo",
  data: function () {
    return {
      tenant: process.env.VUE_APP_TENANT_NAME,
    }
  },
  computed: {
    isLink2Build () { return this.tenant === tenants.link2build; }
  }
};
</script>
